/* Modal Constants */
export const AUTH_MODAL = 'AUTH_MODAL';
export const SCHEDULE_UPLOAD_MODAL = 'SCHEDULE_UPLOAD_MODAL';
export const TRANSCRIPT_UPLOAD_MODAL = 'TRANSCRIPT_UPLOAD_MODAL';
export const COURSE_REVIEW_COURSE_MODAL = 'COURSE_REVIEW_COURSE_MODAL';
export const NOTIFICATION_EMAIL_MODAL = 'NOTIFICATION_EMAIL_MODAL';
export const EDIT_EMAIL_MODAL = 'EDIT_EMAIL_MODAL';
export const DELETE_ACCOUNT_MODAL = 'DELETE_ACCOUNT_MODAL';
export const RESET_PASSWORD_MODAL = 'RESET_PASSWORD_MODAL';

export type ModalName =
  | typeof AUTH_MODAL
  | typeof SCHEDULE_UPLOAD_MODAL
  | typeof TRANSCRIPT_UPLOAD_MODAL
  | typeof COURSE_REVIEW_COURSE_MODAL
  | typeof NOTIFICATION_EMAIL_MODAL
  | typeof EDIT_EMAIL_MODAL
  | typeof DELETE_ACCOUNT_MODAL
  | typeof RESET_PASSWORD_MODAL;
